const point = "https://empb.rekopgames.com"
const mediapoint = "https://cb.rekopgames.com"

/* SOCKET TOKEN */
const DEALER_MIDDLEWARE_TOKEN = "4ae72e0e-83f6-4884-8fca-ba37c4bcb74e"

/* DEALER MONITOR TOKEN */
const DEALER_MONITOR_TOKEN = "471d814f-f2fe-493a-8e2f-d7c32404e95a"

/* POINTS */
const startpoint = `${point}/api/`

export { point, mediapoint, startpoint, DEALER_MONITOR_TOKEN, DEALER_MIDDLEWARE_TOKEN }
